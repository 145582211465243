
































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































































import {
  defineComponent,
  computed,
  PropType,
  ref,
  nextTick,
} from "@vue/composition-api";

import { HomeVideoElementType } from "./HomeVideoElementType.types";

export default defineComponent({
  name: "HomeVideoElement",
  components: {},
  props: {
    records: {
      type: Object as PropType<HomeVideoElementType>,
    },
  },

  setup(props) {
    const startVideo = ref(false);
    const videoRef = ref();

    const record = computed(() => props.records);

    const playVideo = () => {
      startVideo.value = true;
      nextTick(() => {
        const elem = videoRef.value as HTMLElement & {
          mozRequestFullScreen(): Promise<void>;
          webkitRequestFullscreen(): Promise<void>;
          msRequestFullscreen(): Promise<void>;
        };
        if (window.innerWidth <= 600) {
          var fullscreen =
            elem.webkitRequestFullscreen ||
            elem.mozRequestFullScreen ||
            elem.msRequestFullscreen;
          fullscreen.call(elem);
        } else {
          return;
        }
      });
    };

    return {
      startVideo,
      playVideo,
      videoRef,
      record,
    };
  },
});
