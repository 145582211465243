import Vue from "@/helper/initVue";

import { HomeVideoElement } from "@/features/home-video-element";

const elements = document.querySelectorAll(".home-video-element");

  Array.from(elements).forEach((element) => { 
    try {
      const elRecord = element.getAttribute("data-records");

        if (element) {
        new Vue({
            el: element,
            render: (h) =>
                h(HomeVideoElement, {
                    props: {
                        records: elRecord ? JSON.parse(elRecord) : {},
                    },
                }),
            })
        }    
    }
    catch (err) {
        console.log("Error")
    }
})